import React from 'react';
import { Home, Stack, Contact } from './';

const Main = () => (
    <main>
        <Home />
        <Stack />
        <Contact />
    </main>
);

export default Main;