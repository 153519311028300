import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';



const Home = () => {
    // useNav takes in a navLinkId and returns a ref
    // this ref is used to register the navLinkId that's
    // currently in view, and apply activeClass styling
    // to the corresponding nav childElement

    const homeRef = useNav('Home');

    return (
        <section ref={homeRef} id='homeContainer'>
            <h1>Data engineering consulting and staff augmentation solutions</h1>
            <p>
                We provide the full scope of data Engineering consulting services: from reviewing your current data architecture to building data pipelines for your project.
                <br />
                Our specialization is in building, designing, maintaining, optimizing data pipelines and machine learning models, as well as providing Staff Augmentation Services.
            </p>
            <div>
                <section className='list-with-heading'>
                    <h2>Data Engineering</h2>
                    <ul>
                        <li>Data infrastructure</li>
                        <li>Data storage</li>
                        <li>Data pipelines</li>
                    </ul>
                </section>

                <section className='list-with-heading'>
                    <h2>Data Orchestration</h2>
                    <ul>
                        <li>Custom made solutions</li>
                        <li>ETL Tools</li>
                        <li>Monitoring and Alerting</li>
                        <li>Orchestration tools</li>
                        <li>CI / CD</li>
                        <li>Failover Strategies</li>
                    </ul>
                </section>

                <section className='list-with-heading'>
                    <h2>Data Logging & Monitoring</h2>
                    <ul>
                        <li>Collection</li>
                        <li>Processing</li>
                        <li>Accessibility</li>
                        <li>Analytics</li>
                        <li>Storage</li>
                        <li>Retention Cycles</li>
                    </ul>
                </section>
            </div>


        </section>
    );
};

export default Home;