import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
import { ReactComponent as Logo500 } from '../assets/logo500.svg';

const Contact = () => {
    // useNav takes in a navLinkId and returns a ref
    // this ref is used to register the navLinkId that's
    // currently in view, and apply activeClass styling
    // to the corresponding nav childElement

    const contactRef = useNav('Contact');

    return (
        <section ref={contactRef} id='contactContainer'>
            <h1>You can find us at:</h1>
            <div>
                <section>
                    <h2>Eu Office</h2>
                    <address>
                        E-mail <a href="mailto:office-ns@functorns.com">office-ns@functorns.com</a>
                        <br />
                        LinkedIn <a href="https://www.linkedin.com/company/functorns/about/">FunctorNS LLC</a>
                        <br />
                        Phone <a href="tel:+38163 566034">+381 63 566 034</a>
                    </address>
                </section>
                <section>
                    <h2>US Office</h2>
                    <address>
                        E-mail <a href="mailto:office-ny@functorns.com">office-ny@functorns.com</a>
                        <br />
                        LinkedIn <a href="https://www.linkedin.com/company/functorns/about/">FunctorNS LLC</a>
                        <br />
                        Phone US <a href="tel:+12678488686">(267) 848-8686</a>
                    </address>
                </section>
            </div>
        </section>
    );
};

export default Contact;