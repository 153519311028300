import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
import {
        Docker,
        Hadoop,
        Kafka,
        Hive,
        Spark,
        Kubernetes,
        Nifi,
        Python,
        Scala,
        Airflow,
        PostgresSQL,
        Aws,
        Azure,
        Gcp
} from '../assets/images/images';

class TileGrid extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Tile displayName={"Scala"} src={Scala}/>
                <Tile displayName={"Kafka"} src={Kafka}/>
                <Tile displayName={"Nifi"} src={Nifi}/>
                <Tile displayName={"Python"} src={Python}/>
                <Tile displayName={"Hadoop"} src={Hadoop}/>
                <Tile displayName={"Docker"} src={Docker}/>
                <Tile displayName={"Spark"} src={Spark}/>
                <Tile displayName={"Hive"} src={Hive}/>
                <Tile displayName={"Kubernetes"} src={Kubernetes}/>
                <Tile displayName={"Airflow"} src={Airflow}/>
                <Tile displayName={"PostgresSQL"} src={PostgresSQL}/>
                <Tile displayName={"Aws"} src={Aws}/>
                <Tile displayName={"Azure"} src={Azure}/>
                <Tile displayName={"Gcp"} src={Gcp}/>
            </React.Fragment>
        )
    }
}

class Tile extends React.Component {
    render() {
        return (
            <img className='tile' src={this.props.src} alt=""/>
        );
    }
}


const Stack = () => {
    // useNav takes in a navLinkId and returns a ref
    // this ref is used to register the navLinkId that's
    // currently in view, and apply activeClass styling
    // to the corresponding nav childElement

    const stackRef = useNav('Tech Stack');

    return (
        <section ref={stackRef} id='stackContainer'>
            <div>
                <h1>Technology Stack</h1>
                <div className='tileGrid'>
                    <TileGrid />
                </div>
                <h2>and more!</h2>
            </div>
        </section>
    );
};

export default Stack;