import React from 'react';
import NavLink from './NavLink';
import { navLinks } from './navLinks';
import './Nav.css';
import { ReactComponent as Logo } from '../assets/logo275.svg';

const Nav = () => {
    return (
        <nav>
            <Logo  height={60} width={100} />
            {navLinks.map(({ navLinkId, scrollToId }, idx) => (
                <NavLink key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
            ))}
        </nav>
    );
};

export default Nav;